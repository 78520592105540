<template>
	<!-- 重命名文件对话框 -->
	<el-dialog
		title="重命名文件"
		:visible.sync="visible"
		:close-on-click-modal="false"
		width="550px"
		@open="handleDialogOpen"
		@close="handleDialogClose"
	>
		<el-form
			class="rename-file-form"
			:model="form"
			:rules="formRules"
			ref="renameFileForm"
		>
			<el-form-item label="文件名称" prop="fileName">
				<el-input
					v-model="fileInfo.fileName"
					placeholder="请输入文件名称"
					type="text"
					style="width: 100%"
					maxlength="60"
					show-word-limit
				></el-input>
			</el-form-item>
			<el-row>
				<el-col :span="24">
					<el-form-item label="标题" prop="title" v-if="fileInfo.isDir === 0">
						<el-input
							v-model="fileInfo.title"
							placeholder="请输入标题"
							type="textarea"
							rows="2"
							maxlength="100"
							show-word-limit
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="50">
				<el-col :span="12">
					<el-form-item label="作者" prop="author" v-if="fileInfo.isDir === 0">
						<el-input
							v-model="fileInfo.author"
							placeholder="请输入作者名称"
							type="text"
							style="width: 90%"
							maxlength="60"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						label="发表日期"
						prop="publicDate"
						v-if="fileInfo.isDir === 0"
					>
						<el-date-picker
							v-model="fileInfo.publicDate"
							type="date"
							placeholder="选择日期"
							format="yyyy-MM-dd"
							style="width: 90%"
							:editable="false"
							:clearable="false"
							:picker-options="setDisabled"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="50">
				<el-col :span="12">
					<el-form-item label="来源" prop="source" v-if="fileInfo.isDir === 0">
						<el-input
							v-model="fileInfo.source"
							placeholder="请输入来源出处"
							type="text"
							maxlength="60"
						></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="点击量" prop="hits" v-if="fileInfo.isDir === 0">
						<el-input
							v-model.number="fileInfo.hits"
							placeholder="请输入点击量"
							type="text"
							style="width: 90%"
							maxlength="60"
							oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
						>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col>
					<el-form-item label="摘要" prop="digest" v-if="fileInfo.isDir === 0">
						<el-input
							v-model="fileInfo.digest"
							placeholder="请输入摘要"
							type="textarea"
							maxlength="500"
							show-word-limit
							rows="5"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col>
					<el-form-item
						label="标识"
						prop="identifier"
						v-if="fileInfo.isDir === 0"
						v-show="this.form.showEL == true"
					>
						<el-input v-model="fileInfo.identifier"> </el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="handleDialogClose">取 消</el-button>
			<el-button
				type="primary"
				:loading="sureBtnLoading"
				@click="handleDialogSure('renameFileForm')"
				>确 定</el-button
			>
		</div>
	</el-dialog>
</template>

<script>
import { renameFile } from '_r/file.js'

export default {
	name: 'RenameFileDialog',
	data() {
		return {
			visible: false, //  对话框是否可见
			form: {
				fileName: '',
				hit: 0,
				showEL: false
			},
			formRules: {
				fileName: [
					{ required: true, message: '请输入文件名称', trigger: 'blur' }
				]
			},
			sureBtnLoading: false,
			setDisabled: {
				disabledDate(time) {
					return time.getTime() > Date.now() // 可选历史天、可选当前天、不可选未来天
				}
			}
		}
	},
	methods: {
		/**
		 * 重命名文件对话框 | 对话框打开的回调
		 * @description 表单项回显原本的文件名
		 */
		handleDialogOpen() {
			this.form.fileName = this.oldFileName
			this.form.hit = this.oldhit
		},
		/**
		 * 重命名文件对话框 | 对话框关闭的回调
		 * @description 关闭对话框，重置表单
		 */
		handleDialogClose() {
			this.$refs['renameFileForm'].resetFields()
			this.visible = false
			this.callback('cancel')
		},
		/**
		 * 重命名文件对话框 | 确定按钮点击事件
		 * @description 校验表单，校验通过后调用重命名文件接口
		 * @param {string} formName 表单ref值
		 */
		handleDialogSure(formName) {
			this.sureBtnLoading = true
			this.$refs[formName].validate((valid) => {
				if (valid) {
					renameFile({
						userFileId: this.userFileId,
						fileName: this.fileInfo.fileName,
						title: this.fileInfo.title,
						author: this.fileInfo.author,
						digest: this.fileInfo.digest,
						source: this.fileInfo.source,
						//点击量
						identifier: this.fileInfo.identifier,
						hits: this.fileInfo.hits,
						publicDate: this.fileInfo.publicDate
					})
						.then((res) => {
							this.sureBtnLoading = false
							if (res.success) {
								this.$message.success('重命名成功')
								this.$refs[formName].resetFields()
								this.visible = false
								this.callback('confirm')
							} else {
								this.$message.error(res.message)
							}
						})
						.catch(() => {
							this.sureBtnLoading = false
						})
				} else {
					this.sureBtnLoading = false
					return false
				}
			})
		}
	}
}
</script>
